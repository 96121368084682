<template>
	<LayoutWide>
		<sub-nav-bar page="Marketing" />
		<div>
			<HeroBox :title="title" :subtitle="subtitle" :color="color" />
		</div>
		<section>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="selectedBeginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="selectedEndingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-button
				class="is-info form-btn margin-top-20"
				style="width: 200px; margin-bottom: 30px; margin-left: 0px"
				type="submit"
				@click="fetchDates"
				>Get Today's Results</b-button
			>
		</section>

		<div style="background-color: rgb(76, 92, 122)">
			<div class="table-header">{{ tableTitle }}</div>
		</div>
		<vue-good-table
			:columns="columnsDailyVisitList"
			:rows="rows"
			styleClass="vgt-table striped bordered"
			theme="black-rhino"
			max-height="700px"
			:fixed-header="true"
			@on-cell-click="onCellClick"
			:search-options="{
				enabled: true,
			}"
			:sort-options="sortOptions"
			:pagination-options="{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [5, 10, 15, 20],
				dropdownAllowAll: true,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field === 'select'">
					<b-button class="is-small is-success">Visit</b-button>
				</span>
				<span v-else-if="props.column.field === 'alarm'">
					<b-button class="is-small is-danger">Alarm</b-button>
				</span>
				<span v-else-if="props.column.field === 'edit'">
					<b-button class="is-small is-info">Edit</b-button>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
		<div
			style="
				background-color: rgb(76, 92, 122);
				padding-bottom: 30px;
				display: flex;
				justify-content: space-between;
			"
		>
			<div>
				<b-button
					class="margin-top-30 margin-left-15 width-180"
					style="background-color: rgb(72, 199, 142); color: white"
					@click="refetch()"
				>
					Refresh the Data
				</b-button>
				<b-button
					class="margin-top-30 margin-left-30 width-180"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="addNewVisit"
				>
					Add New Visit
				</b-button>
				<b-button
					class="margin-top-30 margin-left-30 width-180"
					style="background-color: rgb(62, 142, 209); color: white"
					@click="previewVisitList"
				>
					Preview the Visit List
				</b-button>
			</div>
			<div>
				<download-csv :data="mappedRows" :name="fileName">
					<div class="margin-top-30 width-180" style="margin-right: 15px">
						<b-button type="is-danger" outlined> Download Visit List </b-button>
					</div>
				</download-csv>
			</div>
		</div>
		<div v-if="isAllVisitableCustomersModalFrameVisible">
			<AllVisitableCustomersModalFrame />
		</div>
		<div v-if="isPreviewVisitListVisible">
			<PreviewVisitList :data="previewData" :columns="previewColumns" />
		</div>
		<div v-if="isFrameVisible">
			<CreateCustomerVisitFrame />
		</div>
	</LayoutWide>
</template>

<script>
import {
	ref,
	reactive,
	watch,
	watchEffect,
	computed,
	onMounted,
} from '@vue/composition-api'
import SubNavBar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import PreviewVisitList from '@/_srcv2/pages/business-development/daily-visits/components/PreviewVisitList.vue'
import AllVisitableCustomersModalFrame from '@/_srcv2/pages/business-development/daily-visits/components/AllVisitableCustomersModalFrame.vue'
import CreateCustomerVisitFrame from '@/_srcv2/pages/business-development/customer-visits/components/CreateCustomerVisitFrame.vue'
import useCreateCustomerVisitState from '@/_srcv2/pages/business-development/customer-visits/scripts/useCreateCustomerVisitState.js'
import dateformat from 'dateformat'
import { useQuery } from '@vue/apollo-composable'
import GetCustomerVisitListQuery from '@/_srcv2/pages/business-development/daily-visits/graphql/GetCustomerVisitListQuery.graphql'
import Store from '@/store'

export default {
	name: 'DailyVisitsList',
	components: {
		SubNavBar,
		HeroBox,
		AllVisitableCustomersModalFrame,
		PreviewVisitList,
		CreateCustomerVisitFrame,
	},
	setup() {
		const title = ref('Customers Visits List (All Visits)')
		const subtitle = ref(
			'You can see all the customer visits here and you can also add new customer visits here',
		)
		const color = ref('is-info')
		const tableTitle = ref('Customer Visits to be done')
		const {
			columnsDailyVisitList,
			isPreviewVisitListVisible,
			isAllVisitableCustomersModalFrameVisible,
			isFrameVisible,
			noteType,
			mutationType,
			customerID,
			addressID,
			visitID,
			visitNotes,
			visitAlarmNotes,
			customer,
			keyCreateCustomerVisitFrame,
			fireRefetch,
		} = useCreateCustomerVisitState()
		const rows = ref([])
		const disableButtons = ref(false)
		// todo ------------------------------------------------
		// const getData = () => {
		// 	console.log('getData is fired')
		// }
		// todo ------------------------------------------------
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		const today = ref(new Date())
		const fetchDates = () => {
			return new Promise((resolve, reject) => {
				if (today.value !== null) {
					selectedBeginningDate.value = today.value
					selectedEndingDate.value = today.value
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		// todo ------------------------------------------------
		const onAddVisit = () => {
			console.log('onAddVisit')
			disableButtons.value = true
			setTimeout(() => {
				disableButtons.value = false
			}, 1000)
		}
		// todo ------------------------------------------------
		const queryVariables = computed(() => {
			return {
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}
		})
		const sortOptions = reactive({
			enabled: true,
			multipleColumns: true,
			initialSortBy: [{ field: 'visit_date', type: 'asc' }],
		})
		onMounted(() => {
			refetch()
			// ? -------------------
			sortOptions.initialSortBy = [{ field: 'visit_date', type: 'asc' }]
		})
		const options = reactive({
			fetchPolicy: 'cache-first', // cache-first, network-only, no-cache, cache-and-network, cache-only
		})
		const { refetch, onResult } = useQuery(
			GetCustomerVisitListQuery,
			() => queryVariables.value,
			options,
		)
		onResult((result) => {
			console.log('onResult fired', result.data.customers_visits)
			fetchAddresses(result.data.customers_visits).then(
				(visits) => (rows.value = visits),
			)
		})
		const fetchAddresses = (data) => {
			return new Promise((resolve, reject) => {
				const visits = data.map((item) => {
					return {
						visit_id: item.visit_id,
						visit_date: item.visit_date,
						customerId: item._customers.customer_id,
						customer: `${item._customers.customer_id} - ${item._customers.customer_title} - ${item._customers.customer_nickname}`,
						visit_notes: item.visit_notes,
						is_customer_visited: item.is_customer_visited,
						address: `${item._customers._addresses[0].line_1} ${item._customers._addresses[0].line_2}
            ${item._customers._addresses[0].post_code} ${item._customers._addresses[0].city}`,
						addressId: item.address_id,
						customer_type: item._customers.customer_type,
					}
				})
				if (data !== null || undefined) {
					resolve(visits)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		watch(
			() => fireRefetch.value,
			(value) => {
				refetch()
				console.log('values: ', value)
			},
		)
		const disableDownloadButton = computed(() => {
			return rows.value.length === 0
		})
		const selectedCustomerType = ref('')
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				noteType.value = 'visitNote'
				mutationType.value = 'add'
				customerID.value = params.row.customerId
				addressID.value = params.row.addressId
				visitNotes.value = ''
				customer.value = params.row.customer
				console.log('params.row', params.row)
				isFrameVisible.value = true
				keyCreateCustomerVisitFrame.value += 1
			} else if (params.column.field === 'alarm') {
				noteType.value = 'alarmNote'
				mutationType.value = 'add'
				customerID.value = params.row.customerId
				addressID.value = params.row.addressId
				visitAlarmNotes.value = ''
				customer.value = params.row.customer
				console.log('params.row', params.row)
				isFrameVisible.value = true
				keyCreateCustomerVisitFrame.value += 1
			} else if (params.column.field === 'edit') {
				noteType.value = 'visitNote'
				mutationType.value = 'edit'
				customerID.value = params.row.customerId
				visitID.value = params.row.visit_id
				visitNotes.value = params.row.visit_notes
				customer.value = params.row.customer
				console.log('params.row', params.row)
				isFrameVisible.value = true
				keyCreateCustomerVisitFrame.value += 1
			}
		}
		const mappedRows = computed(() => {
			return rows.value.map((row) => {
				return {
					'Visit Date': row.visit_date,
					Customer: row.customer,
					'Visiting Address': row.address,
				}
			})
		})
		// todo ------------------------------------------------
		const previewColumns = [
			{
				field: 'visit_date',
				label: 'Visit Date',
			},
			{
				field: 'customer',
				label: 'Customer',
			},
			{
				field: 'address',
				label: 'Visiting Address',
			},
		]
		const previewData = computed(() => {
			return rows.value.map((row) => {
				return {
					visit_date: row.visit_date,
					customer: row.customer,
					address: row.address,
				}
			})
		})
		// todo ------------------------------------------------
		const addNewVisit = () => {
			console.log('addNewVisit is fired')
			isAllVisitableCustomersModalFrameVisible.value = true
		}
		const previewVisitList = () => {
			console.log('previewVisitList')
			isPreviewVisitListVisible.value = true
		}
		const fileName = ref('customer-visits-list.csv')
		return {
			selectedCustomerType,
			tableTitle,
			sortOptions,
			columnsDailyVisitList,
			isPreviewVisitListVisible,
			isAllVisitableCustomersModalFrameVisible,
			isFrameVisible,
			rows,
			onCellClick,
			mappedRows,
			title,
			subtitle,
			color,
			disableButtons,
			onAddVisit,
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			fetchDates,
			disableDownloadButton,
			addNewVisit,
			previewVisitList,
			fileName,
			previewColumns,
			previewData,
			// ------------------------------------------------
			fetchAddresses,
			refetch,
		}
	},
}
</script>

<style lang="scss" scoped></style>
